<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Certificate
            <v-spacer></v-spacer>
            <v-btn
                :color="'success'"
                class="white--text"
                outlined
                small
                v-if="($auth.isAdmin() || $auth.loggedInRole().slug === 'administrator') &&certificate_template.length > 0 && !!this.selected_template_id"
                @click="issueDateDialog = true"
              >
                <v-icon left small>cloud_download</v-icon>
                &nbsp;Download Certificate 
            </v-btn>
            &nbsp;
            &nbsp;
            <v-download-column-select
              :hide-download="true"
              option-type="radio"
              v-if="
                ($auth.isAdmin() ||
                  $auth.loggedInRole().slug === 'administrator') &&
                certificate_template.length > 0
              "
              :showPDF="false"
              :downloadColumns="certificate_template"
              @selectedItem="selectedTemplateItem"
            >
              SELECT TEMPLATE
            </v-download-column-select>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs4 mt-1>
                <v-select
                  :loading="examLoading"
                  :disabled="!exams.length"
                  outlined
                  dense
                  :items="exams"
                  class="pa-0"
                  label="Exam"
                  v-model="exam"
                />
              </v-flex>
              <v-flex xs3 sm2>
                <v-select
                  :loading="gradeLoading"
                  persistent-hint
                  outlined
                  dense
                  :items="grades"
                  class="pa-0"
                  label="Grade"
                  v-model="grade"
                  :disabled="
                    !exam || !grades.length || exam === 'see' || exam === 'ble'
                  "
                />
              </v-flex>
              <v-flex xs3 sm2>
                <v-select
                  :items="sections"
                  class="pa-0"
                  label="Section"
                  v-model="section"
                  outlined
                  dense
                  :disabled="!grade"
                />
              </v-flex>
              <v-flex xs2 sm 2>
                <v-text-field
                  autocomplete="off"
                  label="Serial Number"
                  class="pa-0"
                  outlined
                  dense
                  v-model="serial_number"
                  name="serial_number"
                />
              </v-flex>
              <v-flex xs1 sm1>
                <search-button :permission="true" @action="get(),getCertificateContent()">
                </search-button>
              </v-flex>
              <v-flex xs1>
                  <transition name="animate-css-transition" enter-active-class="animated fadeInRight"
                    leave-active-class="animated fadeOutRight">
                    <v-btn v-if="exam || grade || section || serial_number" id="staff_clear_filter" outlined color="primary"
                      text class="mt-n7-2 staff_clear_filter"
                      @click="exam = '', grade = '', section = '', serial_number = '',get()">
                      Clear
                    </v-btn>
                  </transition>
                </v-flex>
            </v-card-title>
            <!-- <v-fab-transition>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    style="position: absolute; right: 10px; top: 20px"
                    color="blue"
                    title="Print Bulk"
                    fab
                    dark
                    small
                    right
                    bottom
                    @click="
                      (print.enrollId = ''),
                        (print.sectionId = section),
                        printCertificateBulk()
                    "
                  >
                    <v-icon>print</v-icon>
                  </v-btn>
                </template>
                <span>Print all certificate.</span>
              </v-tooltip>
            </v-fab-transition> -->
          </v-card>
          <br />
          <v-data-table
            :headers="headers"
            hide-default-footer
            :items="form.items.data"
            :loading="form.loading"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            class="marks_entry certificate_table"
          >
            <template v-slot:item="{ index, item }">
              <tr :class="''">
                <td class="text-xs-center">{{ item.roll }}</td>
                <td class="text-xs-left">
                  <strong>
                    {{ item.serial_number }}
                  </strong>
                </td>
                <td class="text-xs-left">
                  <strong>{{ item.name }}</strong>
                </td>
                <td class="text-xs-center">
                  <table style="padding-top: 10px">
                    <tr>
                      <td style="padding-left: 0">
                        <span
                          style="
                            padding-right: 20px;
                            display: inline-block;
                            margin-top: 15px;
                          "
                          ><v-calendar-field
                            placeholder="YYYY-MM-DD"
                            :id-val="'start_date-' + index"
                            v-model="item.certificates.from"
                            label="From"
                          >
                          </v-calendar-field
                        ></span>
                      </td>
                      <td style="padding-left: 0">
                        <span
                          style="
                            padding-right: 20px;
                            display: inline-block;
                            margin-top: 15px;
                          "
                          ><v-calendar-field
                            placeholder="YYYY-MM-DD"
                            :id-val="'end_date-' + index"
                            v-model="item.certificates.to"
                            label="To"
                          >
                          </v-calendar-field
                        ></span>
                      </td>
                      <td style="padding-left: 0" v-if="isSelectedGradeMatched">
                        <v-text-field
                          v-model="item.gpa"
                          outlined
                          dense
                          style="width: 100px"
                          autocomplete="off"
                          label="GPA Score"
                          required
                          class="pa-0"
                          name="name"
                        />
                      </td>
                      <td style="padding-left: 0">
                        <v-autocomplete
                          v-model="item.certificates.moral_character"
                          style="width: 170px"
                          :items="moralCharacters"
                          outlined
                          dense
                          label="Character"
                        ></v-autocomplete>
                      </td>
                    </tr>
                    <tr>
                      <td
                        colspan="2"
                        style="padding-left: 0"
                        v-if="isSelectedGradeMatched"
                      >
                        <v-text-field
                          v-model="item.certificates.reg_no"
                          outlined
                          dense
                          style=""
                          autocomplete="off"
                          label="Registration Number"
                          required
                          class="pa-0"
                          name="name"
                        />
                      </td>

                      <td
                        colspan="2"
                        style="padding-left: 0"
                        v-if="isSelectedGradeMatched"
                      >
                        <v-text-field
                          v-model="item.certificates.sym_no"
                          outlined
                          dense
                          style=""
                          autocomplete="off"
                          label="Symbol Number"
                          required
                          class="pa-0"
                          name="name"
                        />
                      </td>
                    </tr>
                  </table>
                </td>
              
                <td class="text-right">
                  <!-- <v-btn color="green"
                    v-if="item.certificates.moral_character && item.certificates.to &&item.certificates.from"
                    title="Print single"
                    dark
                    small
                    bottom
                    @click="selectedData=item, makCopyDialog=true, selectedStudentName = item.name, getCerfificateCopies(item.enroll_id)"

                  >
                    Make Copy of Original

                  </v-btn> -->
                  <v-tooltip
                    v-if="
                      item.certificates.moral_character &&
                      item.certificates.to &&
                      item.certificates.from
                    "
                    bottom
                  >
                    <template v-slot:activator="{ on }">
                      <!--<v-icon color="primary" dark v-on="on">home</v-icon>-->
                      <v-btn
                        v-on="on"
                        color="warning"
                        title="Make copy of original"
                        fab
                        dark
                        small
                        right
                        bottom
                        @click="selectedData=item, makCopyDialog=true, selectedStudentName = item.name, getCerfificateCopies(item.enroll_id)"

                      >
                        <v-icon>file_copy</v-icon>
                      </v-btn>
                    </template>

                    <span>Make Copy of original</span>
                  </v-tooltip>
                  &nbsp;

                  <v-tooltip
                    v-if="
                      item.certificates.moral_character &&
                      item.certificates.to &&
                      item.certificates.from
                    "
                    bottom
                  >
                    <template v-slot:activator="{ on }">
                      <!--<v-icon color="primary" dark v-on="on">home</v-icon>-->
                      <v-btn
                        v-on="on"
                        color="green"
                        title="Print single"
                        fab
                        dark
                        small
                        right
                        bottom
                        @click="(print.onlySave = true), saveAndPrint(item)"
                      >
                        <v-icon>save</v-icon>
                      </v-btn>
                    </template>

                    <span>Save/Update for later</span>
                  </v-tooltip>
                  &nbsp;
                  <v-tooltip bottom>
                    <template
                      v-if="
                        item.certificates.moral_character &&
                        item.certificates.to &&
                        item.certificates.from
                      "
                      v-slot:activator="{ on }"
                    >
                      <!--<v-icon color="primary" dark v-on="on">home</v-icon>-->
                      <v-btn
                        v-on="on"
                        color="blue"
                        title="Print single"
                        fab
                        dark
                        small
                        right
                        bottom
                        @click="
                          (print.onlySave = false),
                            (print.enrollId = item.enroll_id),
                            (print.sectionId = ''),
                            saveAndPrintData(item)
                        "
                      >
                        <v-icon>print</v-icon>
                      </v-btn>
                    </template>

                    <span>Save and print now.</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>

          <v-dialog v-model="issueDateDialog" persistent max-width="290px">
            <v-card>
              <v-card-title class="primary white--text">
                <span class="title">Issue Date</span>
              </v-card-title>
              <v-card-text class="pb-1">
                <br />
                <v-text-field
                  outlined
                  dense
                  label="From Date"
                  v-model="issueDate"
                  prepend-icon="event"
                  :mask="'####-##-##'"
                  :rules="dateValidation"
                  placeholder="YYYY-MM-DD"
                  :height="25"
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="warning"
                  outlined
                  @click="(issueDateDialog = false), (printData = [])"
                  >Close
                </v-btn>
                <v-btn color="success" outlined @click="saveAndPrint(printData)"
                  >Print
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog > 

          <v-dialog v-model="makCopyDialog" persistent max-width="600px">
            <v-card>
              <v-card-title class="primary white--text">
                <span class="title">Make Copy of Original ({{ selectedStudentName }})</span>
              </v-card-title>
              <v-card-text v-if="certificateCopiesData.length> 0">
                <v-data-table
                  hide-default-footer
                  :headers="certificateCopyHeaders"
                  :items="certificateCopiesData"
                >
                  <template v-slot:item="{ index, item }">
                    <tr>
                      <td class="text-left text-bold">
                        {{ item.certificate_issue_date }}
                      </td>
                      <td>
                        {{ item.enroll_code }}
                      </td>
                      <td>
                        {{ item.certification_serial_number }}
                      </td>
                      <td class="text-right">
                        <v-tooltip bottom>
                          <template

                            v-slot:activator="{ on }"
                          >
                            <!--<v-icon color="primary" dark v-on="on">home</v-icon>-->
                            <v-btn
                              v-on="on"
                              color="blue"
                              title="Print single"
                              fab
                              dark
                              small
                              right
                              bottom
                              @click="printCopiedCertificate(item)"
                            >
                              <v-icon>print</v-icon>
                            </v-btn>
                          </template>
                          <span> Print now.</span>
                        </v-tooltip>
                        
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-text>
                <br />
                <br />
                <v-calendar-field
                  placeholder="YYYY-MM-DD"
                  id-val="certificate_issue_date"
                  v-model="certficateDate"
                  label="Certificate Issue Date"
                >
              </v-calendar-field>
                <!-- <v-text-field
                  outlined
                  dense
                  label="Certificate Issue Date"
                  v-model="certficateDate"
                  prepend-icon="event"
                  :mask="'####-##-##'"
                  :rules="dateValidation"
                  placeholder="YYYY-MM-DD"
                  :height="25"
                /> -->
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="warning"
                  outlined
                  @click="(makCopyDialog = false)"
                  >Close
                </v-btn>
                <v-btn :disabled="certficateDate === '' ? true:false" color="success" outlined @click="makeCopy()">Make Copy & Print
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
         

        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import { validateDate } from "../../../../library/helpers";

const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();
import Mixins from "@/library/Mixins";
import { redirectDownloadUrl } from "@/library/helpers";

export default {
  mixins: [Mixins],
  data: () => ({
    selected_template_id: "",
    rowsPerPageItems: { "items-per-page-options": [10, 25, 50, 75, 100, 150] },
    examLoading: false,
    gradeLoading: false,
    subjectLoading: false,
    defaultTemplate: {},
    form: new Form(
      {
        enroll_id: "",
        name: "",
        roll: "",
      },
      "/api/certificate"
    ),
    
    students: [],
    pagination: {
      rowsPerPage: 100,
    },
    printData: [],
    issueDate: nd.format("YYYY-MM-DD"),
    issueDateDialog: false,
    moralCharacters: [
      "Outstanding",
      "Excellent",
      "Good",
      "Statisfactory",
      "Bad",
    ],
    rowsPerPageItems: [5, 10, 25, 50, 75, 100, 150],
    section: "", //'1026',
    sections: [],
    exam: "",
    exams: [],
    grade: "",
    grades: [],
    dateValidation: [(v) => validateDate(v) || "Invalid Date Format"],
    headers: [
      {
        text: "Roll",
        align: "center",
        value: "roll",
        width: 50,
        sortable: false,
      },
      { text: "C.No", align: "left", value: "certificate_number", sortable: false, width: 120 },
      { text: "Name", align: "left", value: "name", sortable: false },
      {
        text: "Certificate Records",
        align: "left",
        value: "theory",
        sortable: false,
      },

      {
        text: "Action",
        align: "right",
        value: "practical",
        width: 300,
        sortable: false,
      },
    ],
    subject_marks_theory: 0,
    subject_marks_practical: 0,
    marks_entry: {},
    evaluation: "",
    showBulkPrint: false,
    print: {
      sectionId: "",
      enrollId: "",
      onlySave: true,
    },
    certificate_template: [],
    serial_number:'',
    makCopyDialog:false,
    selectedStudentName:'',
    certficateDate:'',
    selectedData:'',
    certificateCopyHeaders:[
      {
        text: "Issue Date",
        align: "left",
        value: "issue_date",
        sortable: false,
      }, 
      {
        text: "Enroll Code",
        align: "left",
        value: "enroll_code",
        sortable: false,
      },
      {
        text: "Certificate Number",
        align: "left",
        value: "serial_number",
        sortable: false, 
      },
      { text: "Action", align: "right", sortable: false},


    ],
    certificateCopiesData:[],
  }),
  computed: {
    ...mapState(["batch"]),

    isSelectedGradeMatched() {
      //do not remove
      let output = false;
      let selectedGrade = null;
      let $this = this;
      this.grades.map((item) => {
        if (item.value === $this.grade) {
          selectedGrade = item;
        }
      });

      if (this.exam === "see" || this.exam === "ble") return true;
      return false;
    },
  },
  mounted() {
    this.getExams();
  },
  watch: {
    batch: function (value) {
      this.get();
    },
    grade: function () {
      this.section = "";
      this.subject = "";
      this.form.items.data = [];
      this.students = [];
      this.getSections();
    },
    exam: function () {
      this.section = "";
      this.form.items.data = [];
      this.students = [];
      this.getGrades();
    },
    certificate_template: function () {
      this.form.items.template_name = [];
    },
    subject: function () {
      // this.section = '';
      this.form.items.data = [];
      this.get();
    },
    // section: function () {
    //   this.get();
    //   this.getCertificateContent();
    // },
    pagination: function () {
      this.get();
    },
  },

  methods: {
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map(function (key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
              );
          })
          .join("&") +
        "&sectionId=" +
        this.section.toString()+
        "&serial_number="+ this.serial_number
      );
    },

    selectedTemplateItem(item) {
      this.selected_template_id = item.value;
    },
    updateDetails(value, i, type) {
      this.students[i].certificates[type] = value;
    },
    get(params) {
      if (this.section || this.serial_number) {
        let $this = this;
        let query = [null, undefined].includes(params)
          ? this.queryString()
          : params;
        this.form.get(null, query).then(({ data }) => {
          this.students = data.data.map(function (item) {
            if (item.gpa) {
              $this.showBulkPrint = true;
            }
            return item;
          });
        });
      }
    },
    saveAndPrintData(data) {
      if (!!this.selected_template_id) {
        this.printData = data;
        this.issueDateDialog = true;
      }
    },
    saveAndPrint(singleStudent) {
     
      const { certificates } = singleStudent;
      if(!certificates){
        let queryString =
          "exam=" +
          this.exam +
          "&sectionId=" +
          this.section +
          "&issueDate=" +
          this.issueDate.dateForm() +
          "&gradeId=" +
          this.grade +
          "&templateId=" +
          this.selected_template_id;
          redirectDownloadUrl({
            uri: "/print/certificate",
            queryString: queryString,
          });
      }else {
        if (
          !certificates.from ||
          !certificates.to ||
          !certificates.moral_character
        ) {
          this.$events.fire("notification", {
            message: "Invalid Data",
            status: "error",
          });
          return;
        }
        let queryString =
          "exam=" +
          this.exam +
          "&sectionId=" +
          this.section +
          "&enrollId=" +
          this.print.enrollId +
          "&issueDate=" +
          this.issueDate.dateForm() +
          "&gradeId=" +
          this.grade +
          "&templateId=" +
          this.selected_template_id;
        // this.showBulkPrint = true;
        //http://reporting.test/print/certificate/1:24?exam=31&sectionId=86&enrollId=1317&issueDate=2079-12-07&gradeId=20&templateId=16
  
        // if(!this.print.onlySave){
        //   redirectDownloadUrl({
        //       uri: "/print/certificate",
        //       queryString: queryString,
        //   });
        // }else {
        //     this.$events.fire("notification", {
        //       message: "Certificate updated",
        //       status: "success",
        //     });
        //   }
        this.$rest
          .post("/api/certificate", { certificates: [singleStudent] })
          .then(({ data }) => {
            this.showBulkPrint = true;
            if (!this.print.onlySave) {
              redirectDownloadUrl({
                uri: "/print/certificate",
                queryString: queryString,
              });
              // let url =
              //   data.data.download_url +
              //   "?exam=" +
              //   this.exam +
              //   "&sectionId=" +
              //   this.section +
              //   "&enrollId=" +
              //   this.print.enrollId +
              //   "&issueDate=" +
              //   this.issueDate.dateForm() +
              //   "&gradeId=" +
              //   this.grade +
              //   "&templateId=" +
              //   this.selected_template_id;
              // window.open(url);
              this.issueDateDialog = false;
            } else {
              this.$events.fire("notification", {
                message: "Certificate updated",
                status: "success",
              });
            }
            this.get()
            this.getCertificateContent()
          });
      }
    },
    downloadStudent() {},
    getGrades() {
      this.gradeLoading = true;
      let $this = this;
      this.$rest
        .get("/api/grades?rowsPerPage=25&descending=false&slim=true")
        .then(({ data }) => {
          this.grade = "";

          this.grades = data.data.map((item) => {
            if (
              $this.exam === "see" &&
              (item.name.toLowerCase() === "class 10" ||
                item.name.toLowerCase() === "10" ||
                item.name.toLowerCase() === "x" ||
                item.name.toLowerCase() === "grade 10" ||
                item.name.toLowerCase() === "ten")
            ) {
              $this.grade = item.id;
            }
            if (
              $this.exam === "ble" &&
              (item.name.toLowerCase() === "class 8" ||
                item.name.toLowerCase() === "8" ||
                item.name.toLowerCase() === "viii" ||
                item.name.toLowerCase() === "grade 8" ||
                item.name.toLowerCase() === "eight")
            ) {
              $this.grade = item.id;
            }
            return { value: item.id, text: item.name };
          });
        })
        .finally(() => {
          this.gradeLoading = false;
        });
      this.$rest
        .get("/api/grades?rowsPerPage=20&sortBy=rank&descending=false")
        .then(({ data }) => {
          if(this.students.search) {
            this.students.search.grades = data.data.map((item) => {
              return { value: item.id, text: item.name };
            });
          }
          
        });
    },
    getExams() {
      this.examLoading = true;
      this.$rest
        .get("/api/exam")
        .then(({ data }) => {
          this.exams = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
          this.exams.push({
            value: "see",
            text: "Secondary Education Examination (SEE)",
          });
          this.exams.push({
            value: "ble",
            text: "Basic Level Examination (BLE)",
          });
        })
        .finally(() => {
          this.examLoading = false;
        });
    },
    getSections() {
      this.$rest
        .get("/api/section?batch=" + this.batch.id + "&grade=" + this.grade)
        .then(({ data }) => {
          this.sections = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
    },
    getCertificateContent() {
      this.$rest
        .get("/api/get-certificate-content")
        .then((res) => {
          if (res.data.length > 0) {
            this.defaultTemplate = {};
            this.certificate_template = res.data.map((item) => {
              if (item.status == 1) {
                this.defaultTemplate = item;
              }
              return {
                value: item.id,
                name: item.name.substr(0, 10) + "..",
                selected: false,
              };
            });
            this.selected_template_id = this.defaultTemplate.id;
            this.certificate_template = this.certificate_template.map(
              (item) => {
                if (item.value === this.defaultTemplate.id) {
                  item.selected = true;
                }
                return item;
              }
            );
          }
        })
        .catch((e) => {});
    },
    // getCertificateTemplate() {
    //   this.$rest.get("/api/certificate-template").then((res) => {
    //     this.defaultTemplate = {};
    //     this.certificate_template = res.data.data.map((item) => {
    //       if (item.use_as_default === true || item.is_default === true) {
    //         this.defaultTemplate = item;
    //       }
    //       return {
    //         value: item.id,
    //         name: item.template_name.substr(0, 10) + "..",
    //         selected: false,
    //       };
    //     });
    //     this.selected_template_id = this.defaultTemplate.id;
    //     this.certificate_template = this.certificate_template.map((item) => {
    //       if (item.value === this.defaultTemplate.id) {
    //         item.selected = true;
    //       }
    //       return item;
    //     });
    //   });
    // },

    printCertificateBulk() {
      this.$rest.get("/api/print/certificate").then(({ data }) => {
        let url =
          data.data.download_url +
          "?exam=" +
          this.exam +
          "&sectionId=" +
          this.section +
          "&issueDate=" +
          this.issueDate.dateForm() +
          "&gradeId=" +
          this.grade;
        window.open(url);
      });
    },
    makeCopy(){
      let selectedData = this.selectedData
      this.$rest.post('/api/certificate-copy',{
        certificate_id: selectedData.certificate_id,
        enroll_id: selectedData.enroll_id,
        enroll_code: selectedData.enroll_code,
        exam_id: this.exam,
        grade_id: this.grade,
        section_id: this.section,
        certificate_issue_date: this.certficateDate,
        certification_serial_number: selectedData.serial_number
      }).then((res)=>{
        if(res.status == 200){
          this.printCopiedCertificate(selectedData)
          this.selectedData=''; 
          this.makCopyDialog=false;
          this.selectedStudentName = "";

        }
      })
    },
    getCerfificateCopies(enroll_id){
      this.$rest.get('/api/certificate-copy?enroll_id='+enroll_id)
      .then(({data})=>{
        this.certificateCopiesData = data.data
      })
    }, 
    printCopiedCertificate(item){
      let issue_date = this.certficateDate === '' ? item.certificate_issue_date : this.certficateDate 
      let queryString =
        "exam=" +
        this.exam +
        "&sectionId=" +
        this.section +
        "&enrollId=" +
        item.enroll_id +
        "&issueDate=" +
        issue_date +
        "&gradeId=" +
        this.grade +
        "&templateId=" +
        this.selected_template_id;

        redirectDownloadUrl({
          uri: "/print/certificate",
          queryString: queryString,
        });
    }
  },
};
</script>
<style lang="scss">
.marks_entry {
  input[disabled] {
    background-color: #eee;
    color: #ccc !important;
    border-color: #ccc !important;
    cursor: not-allowed;
  }

  input[value="0"] {
    color: #ccc !important;
    border-color: #ccc !important;
  }

  .mark_entry_input {
    border: 1px solid #ccc;
    font-weight: bold;
    border-radius: 3px;
    width: 60px;
    height: 25px;
    text-align: center;
  }

  .marks_entry_input_container {
    display: flex;
    align-items: center;
    justify-content: center;

    .v-input__slot {
      margin-bottom: 0 !important;
    }

    .v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
      margin-bottom: 0 !important;
    }

    .v-input__control {
      display: -webkit-box !important;
      -webkit-box-orient: horizontal !important;
    }

    .v-input {
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      display: -webkit-box !important;
      display: -ms-flexbox;
      /* display: flex; */
      /* -webkit-box-flex: 1; */
      -ms-flex: 1 1 auto;
      flex: none;
      font-size: 16px;
      text-align: left;
    }
  }

  input:focus {
    outline: none;
  }
}

.v-input--selection-controls {
  margin-right: 20px !important;
  flex: none !important;
}

.certificate_table {
  .v-text-field.v-text-field--enclosed {
    margin-top: 15px;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}
</style>
